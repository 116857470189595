const data = JSON.stringify({
  total_pages: 6,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Dynamika',
      description: '',
      questions: [
        {
          id: 999,
          nr: 999,
          show_id: false,
          question: "Proszę wskazać, czy wystąpiły u Pani zmiany objawów (dolegliwości) od chwili wypełnienia przez Panią na pierwszej wizycie (wyjściowej) części niniejszego kwestionariusza? (Proszę zaznaczyć jedną, najtrafniejszą odpowiedź):",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Tak, czuję się tak dobrze jak zwykle. Wszystkie objawy (dolegliwości) ustąpiły",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, czuję się dużo lepiej. Większość objawów (dolegliwości) ustąpiła",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, czuję się trochę lepiej. Większość objawów (dolegliwości) wciąż się utrzymuje",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Bez zmian, czuję się tak samo. Wszystkie objawy (dolegliwości) wciąż się utrzymują",
              points: 3
            },
            {
              id: 5,
              desc: "",
              name: "Tak, czuję się gorzej. Mój stan się pogorszył",
              points: 4
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Objawy typowe',
      description: 'Proszę podać, czy w ostatnich 24 godzinach zauważył(a) Pan/Pani wymienione poniżej objawy i ocenić ich intensywność:',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Częste oddawanie niewielkich ilości moczu (częste wizyty w toalecie)",
          answers: [
            {
              id: 1,
              desc: "4 razy dziennie lub mniej",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "częściej niż zwykle 5-6 razy dziennie",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "częściej niż zwykle 7-8 razy dziennie",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "9-10 razy dziennie",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Parcie naglące (silna i niekontrolowana potrzeba oddania moczu)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Ból lub pieczenie podczas oddawania moczu",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Wrażenie, że pęcherz nie został w pełni opróżniony po oddaniu moczu",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Ból lub dyskomfort w podbrzuszu (w okolicy nadłonowej)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Krew (widoczna) w moczu (brak menstruacji)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Objawy różnicowe',
      description: 'Proszę podać, czy w ostatnich 24 godzinach zauważył(a) Pan/Pani wymienione poniżej objawy i ocenić ich intensywność:',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Ból w lędźwiach (najczęściej – jednostronny)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Nieprawidłowa wydzielina z pochwy (ilość, kolor, zapach)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Nieprawidłowa wydzielina z ujścia cewki moczowej (bez oddawania moczu)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Podwyższona temperatura ciała (powyżej 37,5°C)/dreszcze Proszę wpisać, jeśli temperatura została zmierzona",
          answers: [
            {
              id: 1,
              desc: "≤37.5 C",
              name: "Nie",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 C",
              name: "Tak, trochę",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 C",
              name: "Tak, znacznie",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 C",
              name: "Tak, bardzo często",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Jakość życia',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Proszę ocenić, jak silnego dyskomfortu doświadczyła Pani z powodu powyższych objawów w ciągu ostatnich 24 godzin (Proszę zaznaczyć jedną, najtrafniejszą odpowiedź):",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie odczuwałam dyskomfortu (Brak objawów. Czułam się tak dobrze jak zwykle)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Odczuwałam lekki dyskomfort (Czułam się nieco gorzej niż zwykle)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Odczuwałam znaczny dyskomfort (Czułam się dużo gorzej niż zwykle)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Odczuwałam bardzo silny dyskomfort (Czułam się okropnie)",
              points: 3
            },
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Proszę wskazać, jak powyższe objawy przeszkadzały Pani w codziennych czynnościach/w pracy w ciągu ostatnich 24 godzin (Proszę zaznaczyć jedną, najtrafniejszą odpowiedź):",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Zupełnie nie miały wpływu (Pracowałam tak jak zwykle w dniu pracy, bez utrudnień)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Miały łagodny wpływ (Objawy sprawiły, że pracowałam mniej)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Miały umiarkowany wpływ (Zwykłe codzienne czynności wymagały znac znego wysiłku)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Miały bardzo duży wpływ (Prawie nie byłam w stanie pracować)",
              points: 3
            },
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Proszę wskazać, jak powyższe objawy przeszkadzały Pani w życiu towarzyskim (odwiedzinach u przyjaciół, wyjściach do restauracji, itp.) w ciągu ostatnich 24 godzin (Proszę zaznaczyć jedną, najtrafniejszą odpowiedź)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Zupełnie nie miały wpływu (Nie miały wpływu na wykonywane przeze mnie czynności. Życie codzienne toczyło się tak jak zwykle)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Miały łagodny wpływ (Mniej czynności niż zwykle)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Miały istotny wpływ (Musiałam spędzać dużo czasu w domu)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Miały bardzo duży wpływ (Prawie nie mogłam wyjść z domu)",
              points: 3
            },
          ]
        },
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: 'Dodatkowe',
      description: 'Proszę podać, czy w momencie wypełniania kwestionariusza występują u Pana/Pani poniższe objawy:',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Miesiączkę?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Tak",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Zespół napięcia przedmiesiączkowego (ZNP)?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Tak",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Objawy menopauzy?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Tak",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Ciążę?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Tak",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Zdiagnozowaną cukrzycę?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nie",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Tak",
              points: "yes"
            }
          ]
        },
      ]
    },
    {
      id: 6,
      page_nr: 6,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
